import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface PrivateRouteProps {
  requiredRoles?: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ requiredRoles, ...rest }) => {
  const isLoggedIn = useSelector((state: any) => state.auth.authentication);
 
  return isLoggedIn  ? <Outlet {...rest} /> : <Navigate to="/login" />;
};

export default PrivateRoute;