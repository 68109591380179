/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import { ContentHeader } from '../components';
import { Card } from 'react-bootstrap';
import { Work, WorkItem, WorkItemList } from '../types/work';

const WorkDetail = () => {

    //Get URL Parameters
    const queryParameters = new URLSearchParams(window.location.search);
    const workNumber = queryParameters.get("WorkNumber");

    //ApplicationDetail Variable
    const [work, setWork] = useState<Work>();


    //FetchData getid
    const fetchApplicationData = async () => {

        console.log(workNumber);

        try {
            const response = await axios.get('https://joao-inacio.outsystemscloud.com/Garage/rest/Garage/GetWork?WorkNumber=' + workNumber, {

            });

            console.log(response);


            setWork(response.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    //Run on Start
    useEffect(() => {
        fetchApplicationData();

    }, []);



    return (
        <>
            <ContentHeader title={work?.WorkNumber} />
            <section className="content">
                <div className="container-fluid">
         
                    <div className="row">
                        <div className="col-6">
                            <div className="card" style={{ "height": "95%" }}>
                                <div className="card-header">
                                    <h3 className="card-title">Client</h3>
                                </div>
                                <div className="card-body">
                                    <dl className="row">
                                        <dt className="col-sm-4">Name</dt>
                                        <dd className="col-sm-8">
                                            {work?.Client.Name}
                                        </dd>
                                        <dt className="col-sm-4">Contact</dt>
                                        <dd className="col-sm-8">
                                            {work?.Client.Contact}
                                        </dd>
                                        <dt className="col-sm-4">Email</dt>
                                        <dd className="col-sm-8 offset-sm-4">
                                            {work?.Client.Email}
                                        </dd>
                                    </dl>

                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <Card style={{ "height": "95%" }}>
                                <Card.Header>Car</Card.Header>
                                <Card.Body>
                                    <dl className="row">
                                        <dt className="col-sm-4">Brand</dt>
                                        <dd className="col-sm-8">
                                            {work?.Car.Brand}
                                        </dd>
                                        <dt className="col-sm-4">Model</dt>
                                        <dd className="col-sm-8">
                                            {work?.Car.Model}
                                        </dd>
                                        <dt className="col-sm-4">Engine</dt>
                                        <dd className="col-sm-8">
                                            {work?.Car.Engine}
                                        </dd>
                                        <dt className="col-sm-4">Plate</dt>
                                        <dd className="col-sm-8">
                                            {work?.Car.LicensePlate}
                                        </dd>
                                        <dt className="col-sm-4">Kms</dt>
                                        <dd className="col-sm-8">
                                            {work?.CarKms}
                                        </dd>
                                    </dl>
                                </Card.Body>
                            </Card>
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {work?.WorkItemList.map((e: any) => (
                                <WorkRow workRow={e} />
                            ))}
                        </div>
                    </div>
                    </div>
            </section>
        </>
    );
};

function WorkRow({ workRow }: { workRow?: WorkItemList }) {

    console.log(workRow);


    return (
        <div className="card">
            <div className="card-header">
                <h1 className="card-title">{workRow?.Description}</h1>
            </div>
            <div className="card-body">
                <b>Labor</b>
                <table className="table table-sm mb-3">
                    <thead>
                        <tr>
                            <th style={{ width: 10 }}>#</th>
                            <th style={{ width: 150 }}>Reference</th>
                            <th >Description</th>
                            <th style={{ width: 80 }}>Value</th>
                            <th style={{ width: 80 }}>Desc</th>
                            <th style={{ width: 80 }}>Qtd</th>
                            <th style={{ width: 80 }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workRow?.MOList?.map((m: any) => (
                            <WorkItems item={m} />
                        ))}
                    </tbody>
                </table>
                <b>Parts</b>
                <table className="table table-sm mb-3">
                    <thead>
                        <tr>
                            <th style={{ width: 10 }}>#</th>
                            <th style={{ width: 150 }}>Reference</th>
                            <th >Description</th>
                            <th style={{ width: 80 }}>Value</th>
                            <th style={{ width: 80 }}>Desc</th>
                            <th style={{ width: 80 }}>Qtd</th>
                            <th style={{ width: 80 }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workRow?.PCSList?.map((p: any, i) => (
                            <WorkItems item={p} index={i + 1} />
                        ))}
                    </tbody>
                </table>
                <b>consumables</b>
                <table className="table table-sm mb-3">
                    <thead>
                        <tr>
                            <th style={{ width: 10 }}>#</th>
                            <th style={{ width: 150 }}>Reference</th>
                            <th >Description</th>
                            <th style={{ width: 80 }}>Value</th>
                            <th style={{ width: 80 }}>Desc</th>
                            <th style={{ width: 80 }}>Qtd</th>
                            <th style={{ width: 80 }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {workRow?.CSList?.map((c: any) => (
                            <WorkItems item={c} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="card-footer">Footer</div>
        </div>
    );
}

function WorkItems({ item, index }: { item?: WorkItem, index?: number }) {
    const value: number = Number(item?.Value) || 0;
    const qtd: number = Number(item?.Qtd) || 0
    const desc: number = Number(item?.Desc) || 0
    const total: number = (valueWithTax(valueWithDesc(value, desc)) * qtd)

    function valueWithDesc(value: number, desc: number) {
        desc = desc === 0 ? 0 : ((100 - desc) / 100);

        return desc === 0 ? value : (value * desc);
    }

    function valueWithTax(value: number) {
        const tax: number = 0.23;
        return (value + (value * tax));
    }

    return (
        <tr>
            <td>{index}</td>
            <td>{item?.Reference}</td>
            <td>{item?.Descriprion}</td>
            <td>{item?.Value || 0}€</td>
            <td>{item?.Desc || 0}</td>
            <td>{item?.Qtd || 0}
            <input className='form-control'/>
            
            </td>
            <td>{total}</td>
        </tr>
    );
}



export default WorkDetail;
