/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import axios from 'axios';
import { useState, useEffect } from "react";
import { ContentHeader } from '../components';
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';

const Works = () => {


    //ApplicationDetail Variable
    const [works, setWorks] = useState([]);


    //FetchData getid
    const fetchApplicationData = async () => {

        try {
            const response = await axios.get('https://joao-inacio.outsystemscloud.com/Garage/rest/Garage/Works', {
        });

            setWorks(response.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    //Run on Start
    useEffect(() => {
        fetchApplicationData();

    }, []);





    return (
        <div>
            <ContentHeader title="Works" />
            <section className="content">
                <div className="container-fluid">
                    <div className='row'>
                        {works.map((w: any) => (
                            <>
                                <CardWork key={w.id} json={JSON.parse(w.JSOIN)} isFinished={w.IsFinished}></CardWork>
                            </>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

function CardWork(props: any) {
    const navigate = useNavigate();

    function goto(workNumber: any) { 
        navigate('/Work?WorkNumber=' + workNumber)
    }

    return (
        <div className="col-3" key={props.key}>
            <div className={props.isFinished ? "small-box bg-success" : "small-box bg-info"}>
                <div className="inner">
                    <h3>
                        {props.json.WorkNumber}
                    </h3>
                    <p>{props.json.Car.Brand + " " + props.json.Car.Model}</p>
                    <p>{props.json.Car.LicensePlate}</p>
                </div>
                <div className="icon">
                    <i className="fas fa-car" />
                </div>
                <a className="small-box-footer" onClick={(q: any) => goto(props.json.WorkNumber)}>
                    More info <i className="fas fa-arrow-circle-right" />
                </a>
            </div>
        </div>
    )
}

export default Works;
